import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FormAnswer, PatientForm } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { DepositInfo } from '../models/deposit-info';

@Injectable({
  providedIn: 'root',
})
export class TeledepositApiService {
  private httpClient = inject(HttpClient);
  private serviceBaseUrl = `${environment.apiBaseUrl}/deposit`;

  public getPatientForm(): Observable<PatientForm> {
    return this.httpClient.get<PatientForm>(`${this.serviceBaseUrl}/form`);
  }

  public createDepositSequence(
    sequenceImages: File[],
    formAnswer: FormAnswer,
  ): Observable<void> {
    // TODO : https://dev.azure.com/Pixacare/Pixacare/_git/pxc-deposit-app/pullRequest/3203#1727267999
    const formData = new FormData();
    sequenceImages.forEach((image) => {
      formData.append(`sequenceImages`, image);
    });
    formData.append('formAnswer', JSON.stringify(formAnswer));
    return this.httpClient.post<void>(
      `${this.serviceBaseUrl}/sequence`,
      formData,
    );
  }

  getDepositInfo(): Observable<DepositInfo> {
    return this.httpClient.get<DepositInfo>(`${this.serviceBaseUrl}/info`);
  }
}
